<template>
    <div id="nav">
        <div id="nav-links">
            <!-- <router-link 
            class="nav-item" id="home"
            v-bind:to="{ name: 'inicio' }">Inicio</router-link>
            <router-link
            class="nav-item" id="about"
            v-bind:to="{name: 'acerca-de'}">Acerca De</router-link>
            <router-link
            class="nav-item" id="projects"
            v-bind:to="{name: 'proyectos'}">Proyectos</router-link>
            <router-link 
            class="nav-item" id="jobSearch"
            v-bind:to="{name: 'busca-empleo'}">Busca Empleo</router-link>
            <router-link 
            class="nav-item" id="contact"
            v-bind:to="{name: 'contacto'}">Contacto</router-link> -->
            <h1 class="nav-item" id="enConstruccion">EN CONSTRUCCÍON</h1>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#nav {
  background-color: #222023;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15vh;
}
.nav-item {
  text-decoration: none;
  text-align: center;
  color: #A54048;
  /* height: 100%; */
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.nav-item:hover {
  background-color: #F7F5F6;
  border-style: solid;
  border-width: 1px;
  border-color: #222023;
}
#nav-links {
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  display: grid;
  /* grid-template-areas: '... home about projects jobSearch contact ...';
  grid-template-columns: 5fr 1fr 1fr 1fr 1fr 1fr 5fr; */
  grid-template-areas: '... enConstruccion ...';
  grid-template-columns: 5fr 5fr 5fr;
}
/* 
#home {
    grid-area: home;
}
#about {
    grid-area: about;
}
#projects {
    grid-area: projects;
}
#contact {
    grid-area: contact;
}
#jobSearch {
    grid-area: jobSearch;
} */
#enConstruccion {
      grid-area: enConstruccion;
}

@media only screen and (min-width: 651px) and (max-width: 1024px) {
  #nav {
    background-color: #222023;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5rem;
  }
  #nav-links {
    display: grid;
    /* grid-template-areas: '... home about projects jobSearch contact ...';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-template-areas: '... enConstruccion ...';
    grid-template-columns: 1fr 1fr 1fr;
  }
  /* #home {
      grid-area: home;
  }
  #about {
      grid-area: about;
  }
  #projects {
      grid-area: projects;
  }
  #contact {
      grid-area: contact;
  }
  #jobSearch {
      grid-area: jobSearch;
  } */
  #enConstruccion {
      grid-area: enConstruccion;
  }
}

@media only screen and (max-width: 650px) {
  #nav {
    background-color: #222023;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5vh;
  }
  #nav-links {
    display: grid;
    /* grid-template-areas: 'home about projects jobSearch contact';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    grid-template-areas: 'enConstruccion';
    grid-template-columns: 1fr;
    padding-top: .5rem;
  }
  /* #home {
      grid-area: home;
      padding-left: 8vw;
  }
  #about {
      grid-area: about;
  }
  #projects {
      grid-area: projects;
  }
  #contact {
      grid-area: contact;
      padding-right: 8vw;
  }
  #jobSearch {
      grid-area: jobSearch;
  } */
  #enConstruccion {
      grid-area: enConstruccion;
  }
}

@media only screen and (max-width: 412px) {
  #nav-links {
    font-size: 1rem;
  }
}

</style>