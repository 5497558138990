import Vue from 'vue'
import Vuex from 'vuex'
import data from './data.js'
import dataEs from './dataEs.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentLanguage: "",
    currentDailyPReview: {
      dprId: 0,
      date: "",
      wdidy: [
        {
          activityId: 0,
          activity: "",
        },
      ],
      wimelt: 0,
      witpft: [
        {
          activityId: 0,
          activity: "",
        },
      ],
    },
    dailyPReviews: data,
    dailyPReviewsEs: dataEs,
    
  },
  mutations: {
    SET_NAV_BAR_LANGUAGE(state, language) {
      state.currentLanguage = language;
    },
    SET_CURRENT_DAILY_PREVIEW(state, dpr) {
      state.currentDailyPReview = dpr;
    },
  },
  actions: {
  },
  modules: {
  }
})
