<template>
  <div id='homeContainer'>
    <div class='meatPotatoes'>
      <h2 id='title'>Programación Web y Software</h2>
      <img class='logo' alt="Campos logo" src="../../assets/Annie_Home_Page_Image.jpg"/>
    </div>
    <english-badge/>
  </div>
</template>

<script>
// @ is an alias to /src
import EnglishBadge from "../../components/EnglishBadge.vue"

export default {
  name: 'Inicio',
  components: {
    EnglishBadge,
  },
  data(){
    return {
      language: 'espanol',
    }
  },
  created() {
    this.$store.commit('SET_NAV_BAR_LANGUAGE', this.language)
  }
}
</script>

<style scoped>
/* .home {
  background-color: blue;
} */

#homeContainer {
  display: grid;
  grid-template-areas: '... meatPotatoes ...';
  grid-template-columns: 1fr 1fr 1fr;
}

.meatPotatoes {
  height: 90vh;
  grid-area: meatPotatoes;
  /* border-style: solid;
  border-width: 1px;
  border-color: #222023; */
  text-align: center;
}

#title {
  padding-top: 2.5vh;
}

.logo {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

@media only screen and (min-width: 651px) and (max-width: 1024px) {
  #homeContainer {
    display: grid;
    grid-template-areas: '... meatPotatoes ...';
    grid-template-columns: 1fr 5fr 1fr;
  }
  .meatPotatoes {
    height: 90vh;
    grid-area: meatPotatoes;
    /* border-style: solid;
    border-width: 1px;
    border-color: #999999; */
    text-align: center;
  }
}

@media only screen and (max-width: 650px) {
  #homeContainer {
    display: block;
  }
  .meatPotatoes {
    height: 90vh;
    text-align: center;
    padding-top: 3vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }
  #title {
    font-size: 2rem;
  }
}

</style>

