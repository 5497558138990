<template>
  <div id='badgeContainer'>
      <div class='badgeActual' v-on:click.prevent="versionEspanol()">
          <nav>ES</nav>
      </div>
  </div>
</template>

<script>
export default {
    name: 'EspanolBadge',
    methods: {
        versionEspanol() {
            if (this.$route.path.includes("home")) {
                this.$router.push({name:'inicio'});
            }
            else if (this.$route.path.includes("about")) {
                this.$router.push({name:'acerca-de'});
            }
            else if (this.$route.path.includes("projects")) {
                this.$router.push({name:'proyectos'});
            }
            else if (this.$route.path.includes("jobSearch")) {
                this.$router.push({name:'busca-empleo'});
            }
            else if (this.$route.path.includes("contact")) {
                this.$router.push({name:'contacto'});
            }
        }
    }
}
</script>

<style>
.badgeActual {
    z-index: 10;
    position: fixed;
    left: 1vw;
    bottom: 3vh;
    border-style: solid;
    border-width: 1px;
    border-color: #7FAEC0;
    border-radius: 50%;
}
.badgeActual nav {
    padding: .25rem;
    /* padding-left: .25rem;
    padding-right: .25rem; */
}

.badgeActual:hover {
    color: #7FAEC0;
    background-color: #F7F5F6;
    border-color: #222023;
}

@media only screen and (max-width: 650px) {
    .badgeActual {
        position: fixed;
        left: 3vw;
        top: 3vh;
        bottom: auto;
    }
}

</style>