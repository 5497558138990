import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/English/Home.vue'
// import About from '../views/English/About.vue'
// import Projects from '../views/English/Projects.vue'
// import Contact from '../views/English/Contact.vue'
// import JobSearch from '../views/English/jobSearch.vue'
import PreHome from '../views/PreHome.vue'
import Inicio from '../views/Espanol/Inicio.vue'
// import AcercaDe from '../views/Espanol/AcercaDe.vue'
// import Proyectos from '../views/Espanol/Proyectos.vue'
// import Contacto from '../views/Espanol/Contacto.vue'
// import BuscaEmpleo from '../views/Espanol/BuscaEmpleo.vue'


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'pre-home',
      component: PreHome
    },
    {
      path: '/en/home',
      name: 'home',
      component: Home
    },
    // {
    //   path: '/en/about',
    //   name: 'about',
    //   component: About
    // },
    // {
    //   path: '/en/projects',
    //   name: 'projects',
    //   component: Projects
    // },
    // {
    //   path: '/en/contact',
    //   name: 'contact',
    //   component: Contact
    // },
    // {
    //   path: '/en/jobSearch',
    //   name: 'job-search',
    //   component: JobSearch
    // },
    {
      path: '/es/inicio',
      name: 'inicio',
      component: Inicio
    }
    // {
    //   path: '/es/acercade',
    //   name: 'acerca-de',
    //   component: AcercaDe
    // },
    // {
    //   path: '/es/proyectos',
    //   name: 'proyectos',
    //   component: Proyectos
    // },
    // {
    //   path: '/es/contacto',
    //   name: 'contacto',
    //   component: Contacto
    // },
    // {
    //   path: '/es/buscaEmpleo',
    //   name: 'busca-empleo',
    //   component: BuscaEmpleo
    // },
  ]
})

export default router
