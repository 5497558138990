<template>
  <div id="app">
    <h1 id='nameTopBar'>Corey Campos</h1>
    <h1 id='firstName' class='nameSideBar'>Corey</h1>
    <router-view/>
    <h1 id='lastName' class='nameSideBar'>Campos</h1>
    <div v-show="this.isNotPreHomeView" id='navBarsContainer'>
      <nav-bar v-if="this.currentLanguage=='english'" class="nav"/>
      <nav-bar-es v-else-if="this.currentLanguage=='espanol'" class="nav"/>
    </div>
  </div>
</template>

<script>
import NavBar from "./components/English/NavBar.vue"
import NavBarEs from "./components/Espanol/NavBarEs.vue"

export default {
    components: {
      NavBar,
      NavBarEs,
    },
    computed: {
      currentLanguage() {
        return this.$store.state.currentLanguage;
      },
      isNotPreHomeView() {
        if (this.$route.name == 'PreHome') {
          return false;
        }
        else {
          return true;
        }
      }
    }
}
</script>


<!-- Application-Wide Styles go here -->
<style>
#app {
  font-family: 'Squada One', cursive;
  font-size: 16;
  color: #A54048;
  background-color: #222023;
  height: 85vh
}
#nameTopBar {
  display: none;
}
.nameSideBar {
  position: absolute;
  top: 45vh;
  color: #ECDBA7;
  font-family: 'Bungee Hairline', cursive;
  font-size: 3rem;
  letter-spacing: 3rem;
  opacity: .35
}

#firstName {
  left: -10%;
  transform: rotate(270deg);
  top: 35vh;
}
#lastName {
  top: 40vh;
  right: -10%;
  transform: rotate(90deg);
}

.nav {
  z-index: 5;
  height: 100%;
}

#nav {
  height: 100%;
}

#nav a.router-link-exact-active {
  color: #7FAEC0;
}

a {
  color: #7FAEC0;
}
.heading {
  color: #A54048;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1rem;
}
p,  li {
  font-family: 'Roboto Condensed', sans-serif;
}

@media only screen and (min-width: 651px) and (max-width: 1024px) {
  #firstName {
    left: -16%;
    transform: rotate(270deg);
    top: 35vh;
    position: fixed;
  }
  #lastName {
    top: 40vh;
    right: -21%;
    transform: rotate(90deg);
    position: fixed;
  }
}

@media only screen and (max-width: 650px) {
  #firstName {
    display: none;
  }
  #lastName {
    display: none;
  }
  #nameTopBar {
    display: inline;
    position: fixed;
    top: -2vh;
    padding-left: 21vw;
    color: #ECDBA7;
    font-family: 'Bungee Hairline', cursive;
    font-size: 1rem;
    letter-spacing: .5rem;
    opacity: .35;
  }
}

@media only screen and (max-width: 360px) {
  #nameTopBar {
    top: -3vh;
    padding-left: 15vw;
  }
}
</style>
