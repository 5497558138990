const dataEs = 

[{
    dprId: 1,
    date: "08/28/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "probé la funcionalidad de git entre la laptop y el ordenador",
      },
      {
        activityId: 2,
        activity: "añadí una vista/página Features",
      },
      {
        activityId: 3,
        activity: "investigué ideas por la forma y funcionalidad del sitio",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "añadí un componente para contener Daily PReview",
      },
      {
        activityId: 2,
        activity: "planeé un mapa general para el sitio",
      },
      {
        activityId: 3,
        activity: "añadí una vista para contener Daily PReviews",
      },
      {
        activityId: 4,
        activity: "diseño básico",
      },
      {
        activityId: 5,
        activity: "la página Home está más refinada",
      },
    ],
  }, 
  {
    dprId: 2,
    date: "08/29/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "el sitio ha sido planeado en el Router",
      },
      {
        activityId: 2,
        activity: "creé el NavBar lo perfeccioné",
      },
      {
        activityId: 3,
        activity: "añadí una foto temporal en la vista Home",
      },
      {
        activityId: 4,
        activity: "decidí la paleta de color",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "implementa la paleta de color",
      },
      {
        activityId: 2,
        activity: "perfecciona la vista Home",
      },
      {
        activityId: 3,
        activity: "haz un componente o vista del Daily PReview",
      },
    ],
  },
  {
    dprId: 3,
    date: "09/01/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "solicité trabajo como desarrollador en dos empresas incluso seguimiento",
      },
      {
        activityId: 2,
        activity: "creó y lo hice más sustancioso un perfil en Glassdoor.com",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "implementé la paleta de colores por coreycampos.com",
      },
      {
        activityId: 2,
        activity: "perfeccioné la vista Home",
      },
      {
        activityId: 3,
        activity: "creó una estructura de componentes por los Daily PReviews",
      },
    ],
  },
  {
    dprId: 4,
    date: "09/02/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "implementé la paleta de colores por cc.com",
      },
      {
        activityId: 2,
        activity: "arreglé la vista de Home",
      },
      {
        activityId: 3,
        activity: "implementé un componente por el Daily PReview y lo incorporé en la vista de Job Search",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "escribe el texto para la vista de About",
      },
      {
        activityId: 2,
        activity: "escribe el texto para la vista de Contacto",
      },
      {
        activityId: 3,
        activity: "logra una organización congruente a lo largo de todas las vistas",
      },
    ],
  },
  {
    dprId: 5,
    date: "09/03/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "anadí C O R E Y   C A M P O S barras laterales",
      },
      {
        activityId: 2,
        activity: "estandaricé la organización espacial a travéz del sitio",
      },
      {
        activityId: 3,
        activity: "escribí el texto para las vistas About, Contact",
      },
      {
        activityId: 4,
        activity: "implementé una página para elegir entre EN/ES",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "funcionalidad para el botón por inglés",
      },
      {
        activityId: 2,
        activity: "funcionalidad para el botón por español",
      },
    ],
  },
  {
    dprId: 6,
    date: "09/05/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "añadí funcionalidad a los botones de inglés/español en la página Pre-Home",
      },
      {
        activityId: 2,
        activity: "creé las versiones españoles de las Vistas",
      },
      {
        activityId: 3,
        activity: "creé un objeto '$state' para condicionar inglés o español",
      },
    ],
    wimelt: 4,
    witpft: [
      {
        activityId: 1,
        activity: "saca NavBar de PreHome",
      },
      {
        activityId: 2,
        activity: "termina con las traducciones a español",
      },
      {
        activityId: 3,
        activity: "añade un símbolo de En/Es para cambiar el lenguaje dentro de cada página",
      },
    ],
  },
  {
    dprId: 7,
    date: "09/07/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "removed NavBar from PreHome",
      },
      {
        activityId: 2,
        activity: "wrote Spanish copy",
      },
      {
        activityId: 3,
        activity: "added badge to switch between En/Es",
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "edit Spanish copy",
      },
      {
        activityId: 2,
        activity: "store DailyPReviews in the $store",
      },
      {
        activityId: 3,
        activity: "implement display choosing element for Daily PReviews",
      },
    ],
  },
  {
    dprId: 8,
    date: "09/08/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "edited Spanish copy",
      },
      {
        activityId: 2,
        activity: "added a section in the $store for Daily PReviews",
      },
      {
        activityId: 3,
        activity: "implemented skeleton of display for chosen Daily PReview",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "implement display for choosing a specific Daily PReview",
      },
      {
        activityId: 2,
        activity: "upload remaining Daily PReviews",
      },
    ],
  },
  {
    dprId: 9,
    date: "09/09/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "creó un elemento para escoger un DPR del pasado",
      },
      {
        activityId: 2,
        activity: "subí los DPRs escritos no subidos",
      },
      {
        activityId: 3,
        activity: "moví la ocasiones de DPR a data.js archivo",
      },
      {
        activityId: 4,
        activity: "implementé la barra de desplazamiento para el elemento de pasadoDpr",
      },
      {
        activityId: 5,
        activity: "puse el dprActual de condición base a lo más reciente dpr",
      },
      {
        activityId: 6,
        activity: "desplegué a coreycampos.com",
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "añade una noticia que dice que la mayoría de los proyectos 'actuales' son, de hecho, inactivos",
      },
      {
        activityId: 2,
        activity: "añade una alerta en PreHome para decir al usuario que el sito no está optimizado para el uso móvil",
      },
      {
        activityId: 3,
        activity: "arregla al elemento dprPasado",
      },
      {
        activityId: 4,
        activity: "haga un archivo dataEs.js para las versiones de los dprs españoles",
      },
      {
        activityId: 5,
        activity: "crea una rama de git por el desarrollo del sitio no listo para la producción",
      },
    ],
  },
  {
    dprId: 10,
    date: "09/12/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "añadí explicación sobre los proyectos actuales todavía no creados",
      },
      {
        activityId: 2,
        activity: "puse una alerta en la vista PreHome para decir al usuario que el sitio no está optimizado por los móviles",
      },
      {
        activityId: 3,
        activity: "arreglé la lista pastDpr - ambos el contenido y el contenedor",
      },
      {
        activityId: 4,
        acitivity: "creé dateEs.js y lo implementé en la vista BuscaEmpleo",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "haz que el sitio exhibe bien en una pantalla de tamaño-tablet",
      },
      {
        activityId: 2,
        activity: "crea una rama de git para el labor actual v. una rama para producción",
      },
    ],
  },
  {
    dprId: 11,
    date: "09/16/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "creé una rama de git para los cambios no-producción",
      },
      {
        activityId: 2,
        activity: "implementé una versión de tamaño tableta en el sitio entero",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "busca cinco más avisos de empleo para solicitar",
      },
      {
        activityId: 2,
        activity: "implementa una versión de tamaño móvil en el sitio entero",
      },
    ],
  },
  {
    dprId: 12,
    date: "09/17/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "encontré más avisos de empleo; solicité trabajo en dos empresas",
      },
      {
        activityId: 2,
        activity: "implementé una versión de tamaño móvil en el sitio entero",
      },
      {
        activityId: 3,
        activity: "en las vistas JobSearch/BuscaEmpleo, creé un un selector desplegable por los dprs del pasado para ser más compatible con los móviles",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "añade una foto, más detalles personales al texto de About",
      },
      {
        activityId: 2,
        activity: "haz secciones en la vista de About - AboutMe, AboutSite, Resume",
      },
      {
        activityId: 3,
        activity: "(tal vez) crea una línea temporal interactiva sobre mi historia de empleo y escuela",
      },
    ],
  },
  {
    dprId: 13,
    date: "09/18/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "solicité trabajo como desarrollador en dos empresas",
      },
      {
        activityId: 2,
        activity: "dividí la vista About en tres partes: AboutMe, AboutWebsite, AboutResume",
      },
      {
        activityId: 3,
        activity: "añadí un selector desplegable para cambiar entre las diferentes versiones de About",
      },
      {
        activityId: 4,
        activity: "añadí fotos pertinentes en About",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "añade una foto del Resume y haz que es interactivo ' haz clic para descaragar mi currículum",
      },
      {
        activityId: 2,
        activity: "escribe el texto para la sección AboutWebsite",
      },
      {
        activityId: 3,
        activity: "escribe el texto para la sección AboutResume",
      },
      {
        activityId: 4,
        activity: "haz que la vista About es pasable en móvil",
      },
    ],
  },
  {
    dprId: 14,
    date: "09/23/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "añadí una foto del Resume en la página About -> sección Resume",
      },
      {
        activityId: 2,
        activity: "añadí un botón que funciona correctamente para descargar el Resume", 
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "haz que la vista About es pasable en móvil",
      },
    ],
  },
  {
    dprId: 15,
    date: "09/25/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "avancé un poquito con la versión móvil de la página About",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "termina con la versión móvil de la página About, ambos la de inglés y la de español",
      },
      {
        activityId: 2,
        activity: "haz una lista de herramientas del sitio que quedan para crear o modificar",
      },
    ],
  },
  {
    dprId: 16,
    date: "09/28/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "finalicé la versión móvil de la página About",
      },
      {
        activityId: 2,
        activity: "actualicé la página AcercaDe a la misma nivel como la página About",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "arregla el botón para descargar el currículum en la versión móvil de About-Resume",
      },
      {
        activityId: 2,
        activity: "empieza el proyecto de conversión de moneda en la forma de una aplicación en la consola",
      },
    ],
  },
  {
    dprId: 17,
    date: "09/29/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "corregí la posición del botón de descargar en le sección About-Resume (versión móvil)",
      },
      {
        activityId: 2,
        activity: "establecí la aplicación de consola Conversión de Monedas",
      },
      {
        activityId: 3,
        activity: "di cuerpo a la interfaz de usuario por Conversión de Monedas",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "termina con la estructura de la navegación a través del proceso de conversión",
      },
      {
        activityId: 2,
        activity: "encuentra la información relevante para las monedas usadas en la aplicación",
      },
    ],
  },
  {
    dprId: 18,
    date: "09/30/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "establecí (generalmente, en la interfaz de usuario) las etapas de la conversión de monedas",
      },
      {
        activityId: 2,
        activity: "completé las rutas para volver al Menú Principal después de y durante el proceso de conversión",
      },
      {
        activityId: 3,
        activity: "perfeccioné la presentación de la consola para el menú",
      },
      {
        activityId: 4,
        activity: "creé un archivo JSON que contiene los objetos de monedas para usar en la aplicación",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "introduce el archivo JSON en la aplicación para que sus datos son utilizables",
      },
      {
        activityId: 2,
        activity: "implementa los cálculos de conversión",
      },
    ],
  },
  





]

export default dataEs