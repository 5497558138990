<template>
  <div id='badgeContainer'>
      <div class='badgeActual' v-on:click.prevent="englishVersion()">
          <nav>EN</nav>
      </div>
  </div>
</template>

<script>
export default {
    name: 'EnglishBadge',
    methods: {
        englishVersion() {
            if (this.$route.path.includes("inicio")) {
                this.$router.push({name:'home'});
            }
            else if (this.$route.path.includes("acercade")) {
                this.$router.push({name:'about'});
            }
            else if (this.$route.path.includes("proyectos")) {
                this.$router.push({name:'projects'});
            }
            else if (this.$route.path.includes("buscaEmpleo")) {
                this.$router.push({name:'job-search'});
            }
            else if (this.$route.path.includes("contacto")) {
                this.$router.push({name:'contact'});
            }
        }
    }
}
</script>

<style>

</style>