const data = 

[{
    dprId: 1,
    date: "08/28/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "tested functionality between laptop and desktop",
      },
      {
        activityId: 2,
        activity: "added a Features page/view",
      },
      {
        activityId: 3,
        activity: "looked into ideas for website form/functionality",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "added a component to hold Daily PReview",
      },
      {
        activityId: 2,
        activity: "planned a rough site map",
      },
      {
        activityId: 3,
        activity: "added a view to hold Daily PReview",
      },
      {
        activityId: 4,
        activity: "rough styling",
      },
      {
        activityId: 5,
        activity: "more polished Home page",
      },
    ],
  }, 
  {
    dprId: 2,
    date: "08/29/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "site mapped out in Router",
      },
      {
        activityId: 2,
        activity: "NavBar created, refined",
      },
      {
        activityId: 3,
        activity: "placeholder image on Home view",
      },
      {
        activityId: 4,
        activity: "color palette decided",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "implement color palette",
      },
      {
        activityId: 2,
        activity: "refine Home view",
      },
      {
        activityId: 3,
        activity: "component/view for Daily PReview",
      },
    ],
  },
  {
    dprId: 3,
    date: "09/01/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "applied to two jobs including follow-up",
      },
      {
        activityId: 2,
        activity: "created/fleshed out profile on Glassdoor.com",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "implement color palette for coreycampos.com",
      },
      {
        activityId: 2,
        activity: "refine Home view",
      },
      {
        activityId: 3,
        activity: "create a component structure for the Daily PReviews",
      },
    ],
  },
  {
    dprId: 4,
    date: "09/02/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "implemented cc.com color palette",
      },
      {
        activityId: 2,
        activity: "cleaned up Home view",
      },
      {
        activityId: 3,
        activity: "implemented component for Daily PReview and integrated into Job Search view",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "write copy for About view",
      },
      {
        activityId: 2,
        activity: "write copy for Contact view",
      },
      {
        activityId: 3,
        activity: "get consistent spatial organization across views",
      },
    ],
  },
  {
    dprId: 5,
    date: "09/03/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "added C O R E Y   C A M P O S sidebars",
      },
      {
        activityId: 2,
        activity: "firmed up spatial organization across pages",
      },
      {
        activityId: 3,
        activity: "wrote copy for About, Contact views",
      },
      {
        activityId: 4,
        activity: "implemented EN/ES page",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "English button functionality",
      },
      {
        activityId: 2,
        activity: "Spanish button functionality",
      },
    ],
  },
  {
    dprId: 6,
    date: "09/05/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "added functionality to English/Español buttons on Pre-Home page",
      },
      {
        activityId: 2,
        activity: "created Spanish versions of Views",
      },
      {
        activityId: 3,
        activity: "created $state object to determine En/Es",
      },
    ],
    wimelt: 4,
    witpft: [
      {
        activityId: 1,
        activity: "remove NavBar from PreHome",
      },
      {
        activityId: 2,
        activity: "finish Spanish translations",
      },
      {
        activityId: 3,
        activity: "add En/Es badge to swap language in-page",
      },
    ],
  },
  {
    dprId: 7,
    date: "09/07/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "removed NavBar from PreHome",
      },
      {
        activityId: 2,
        activity: "wrote Spanish copy",
      },
      {
        activityId: 3,
        activity: "added badge to switch between En/Es",
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "edit Spanish copy",
      },
      {
        activityId: 2,
        activity: "store DailyPReviews in the $store",
      },
      {
        activityId: 3,
        activity: "implement display choosing element for Daily PReviews",
      },
    ],
  },
  {
    dprId: 8,
    date: "09/08/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "edited Spanish copy",
      },
      {
        activityId: 2,
        activity: "added a section in the $store for Daily PReviews",
      },
      {
        activityId: 3,
        activity: "implemented skeleton of display for chosen Daily PReview",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "implement display for choosing a specific Daily PReview",
      },
      {
        activityId: 2,
        activity: "upload remaining Daily PReviews",
      },
    ],
  },
  {
    dprId: 9,
    date: "09/09/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "created display for choosing a past DPR",
      },
      {
        activityId: 2,
        activity: "uploaded remaining written DPRs",
      },
      {
        activityId: 3,
        activity: "moved DPR instances to data.js file",
      },
      {
        activityId: 4,
        activity: "implemented scroll bar in pastDpr element",
      },
      {
        activityId: 5,
        activity: "set default currentDpr to most recent dpr",
      },
      {
        activityId: 6,
        activity: "deployed coreycampos.com",
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "add notice that most of the /'active/' projects are, in fact, inactive",
      },
      {
        activityId: 2,
        activity: "add alert in PreHome to tell user that site is not optimized for mobile use yet",
      },
      {
        activityId: 3,
        activity: "clean up pastDpr element",
      },
      {
        activityId: 4,
        activity: "make dataEs.js file for Es versions of dprs",
      },
      {
        activityId: 5,
        activity: "create git branch for site development not ready to be pushed to production",
      },
    ],
  },
  {
    dprId: 10,
    date: "09/12/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "added clarification about active projects not-yet created",
      },
      {
        activityId: 2,
        activity: "put an alert in PreHome to tell user that website is not optimized for mobile use",
      },
      {
        activityId: 3,
        activity: "cleaned up pastDpr list - both contents and container/display",
      },
      {
        activityId: 4,
        activity: "made dataEs.js and implemented in BuscaEmpleo view",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "implement 'tablet-size' display across site",
      },
      {
        activityId: 2,
        activity: "make git branch for current work vs. production branch",
      },
    ],
  },
  {
    dprId: 11,
    date: "09/16/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "made git branch for non-production changes",
      },
      {
        activityId: 2,
        activity: "implemented 'tablet-size' display across entire site",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "find minimum of 5 more jobs to apply to",
      },
      {
        activityId: 2,
        activity: "implement 'phone-size' display across site",
      },
    ],
  },
  {
    dprId: 12,
    date: "09/17/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "found more job postings; applied to two jobs",
      },
      {
        activityId: 2,
        activity: "implemented mobile display site-wide",
      },
      {
        activityId: 3,
        activity: "in JobSearch/BuscaEmpleo view, created dropdown selector for past dprs to be more mobile friendly",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "add picture, more personal details to About copy",
      },
      {
        activityId: 2,
        activity: "split About page into AboutMe, AboutSite, Resume",
      },
      {
        activityId: 3,
        activity: "(stretch goal) interactive job/school history timeline",
      },
    ],
  },
  {
    dprId: 13,
    date: "09/18/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "applied to 2 developer jobs",
      },
      {
        activityId: 2,
        activity: "split About view into AboutMe, AboutWebsite, AboutResume",
      },
      {
        activityId: 3,
        activity: "added a selector element to switch between the different About Versions",
      },
      {
        activityId: 4,
        activity: "added relevant pictures to About",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "add picture of Resume; make it interactive - click to download Resume",
      },
      {
        activityId: 2,
        activity: "write copy for AboutWebsite section",
      },
      {
        activityId: 3,
        activity: "write copy for AboutResume section",
      },
      {
        activityId: 4,
        activity: "make About view mobile friendly again",
      },
    ],
  },
  {
    dprId: 14,
    date: "09/23/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "added picture of Resume to About -> Resume section",
      },
      {
        activityId: 2,
        activity: "added functional button to download Resume",
      },
    ],
    wimelt: 5,
    witpft: [
      {
        activityId: 1,
        activity: "make About page mobile friendly",
      },
    ],
  },
  {
    dprId: 15,
    date: "09/25/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "made minor progress on mobile version of About page",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "finish mobile version of About page, both English and Spanish",
      },
      {
        activityId: 2,
        activity: "list any remaining features of coreycampos.com to work on",
      },
    ],
  },
  {
    dprId: 16,
    date: "09/28/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "finished mobile version of About page",
      },
      {
        activityId: 2,
        activity: "brought AcercaDe page up to date with About page",
      },
    ],
    wimelt: 7,
    witpft: [
      {
        activityId: 1,
        activity: "fix download button placement issue in mobile About-Resume section",
      },
      {
        activityId: 2,
        activity: "start currency conversion project (or kitchen measurement conversion project)",
      },
    ],
  },
  {
    dprId: 17,
    date: "09/29/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "fixed download button in About-Resume section (mobile version)",
      },
      {
        activityId: 2,
        activity: "got Currency Conversion console application set up",
      },
      {
        activityId: 3,
        activity: "fleshed out the skeleton of the User Interface for Currency Conversion",
      },
    ],
    wimelt: 6,
    witpft: [
      {
        activityId: 1,
        activity: "finish navigation structure through currency conversion process",
      },
      {
        activityId: 2,
        activity: "retrieve relevant information on currencies to be used",
      },
    ],
  },
  {
    dprId: 18,
    date: "09/30/2020",
    wdidy: [
      {
        activityId: 1,
        activity: "worked through the general steps for currency conversion (in User Interface)",
      },
      {
        activityId: 2,
        activity: "finished paths back to Main Menu after/during conversion",
      },
      {
        activityId: 3,
        activity: "cleaned up Menu console presentation",
      },
      {
        activityId: 4,
        activity: "created JSON file containing currency objects to be used",
      },
    ],
    wimelt: 8,
    witpft: [
      {
        activityId: 1,
        activity: "successfully import JSON file into application",
      },
      {
        activityId: 2,
        activity: "implement conversion calculations",
      },
    ],
  },
  




  





]

export default data