<template>
    <div id='preHomeContainer'>
        <div id='buttonsContainer'>
            <button id='englishButton' class='languageButton' v-on:click.prevent="goHome('english')">English</button>
            <button id='botonEspanol' class='languageButton' v-on:click.prevent="goHome('espanol')">Español</button>
        </div>
    </div>                                                                
</template>

<script>
export default {
    name: "pre-home-page",
    data() {
        return {
            language: '',
        };
    },
    methods: {
        goHome (language) {
            if (language == 'english') {
                this.$router.push({name:'home'});
            } 
            else if (language == 'espanol') {
                this.$router.push({name:'inicio'});
            }
        }
    },
    created() {
        this.$store.commit('SET_NAV_BAR_LANGUAGE', this.language)
        // alert("At the moment, this site is not optimized whatsoever for mobile use. Please keep that in mind when judging me." +
        //        "\n \n En este momento, este sitio no está optimizado en ningún sentido por el uso móvil. Por favor, no lo olvides cuando formas una opinión sobre mi sitio.");
    }
}
</script>

<style>
#buttonsContainer {
    position:absolute;
    top:45vh;
    left:33vw;
}
.languageButton {
    font-size: 5vw;
    margin: .5rem;
    font-family: 'Squada One', cursive;
    background-color: #ECDBA7;
    border-radius: 5px;
}
.languageButton:hover {
  background-color: #222023;
  color: #A54048;
}

@media only screen and (min-width: 651px) and (max-width: 1024px) {
    #buttonsContainer {
        position:absolute;
        top:10vh;
        left:50vw;
    }
    #englishButton {
        position:absolute;
        top: 5rem;
        left: -28vw;
    }
    #botonEspanol {
        position:absolute;
        top: 23rem;
        left: -30vw;
    }
    .languageButton {
        font-size: 20vw;
        margin: .5rem;
        font-family: 'Squada One', cursive;
        background-color: #ECDBA7;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 650px) {
    #buttonsContainer {
        position:absolute;
        top:10vh;
        left:50vw;
    }
    #englishButton {
        position:absolute;
        top: 5rem;
        left: -28vw;
    }
    #botonEspanol {
        position:absolute;
        top: 18rem;
        left: -30vw;
    }
    .languageButton {
        font-size: 20vw;
        margin: .5rem;
        font-family: 'Squada One', cursive;
        background-color: #ECDBA7;
        border-radius: 5px;
    }
}

</style>